e.scrollToAnchor = {
    init: function () {
        e.scrollToAnchor.animating = false;
        e.scrollToAnchor.bindEvents();
        e.scrollToAnchor.backToTop.init();
    },
    bindEvents: function () {
        jQuery( 'a[href*="#"]' ).not( 'a[data-toggle],a[data-slide]' ).on( 'click', function ( event ) {
            if ( this.hash === '#top' ) {
                event.preventDefault();
                e.scrollToAnchor.animate( 0 );
            }
            else if ( location.pathname.replace( /^\//, '' ) === this.pathname.replace( /^\//, '' ) && location.hostname === this.hostname ) {
                var jTarget = jQuery( this.hash ),
                    jTargetSection = jTarget.closest( '.paragraph-section' ),
                    intScrollTarget;
                if ( jTarget.length === 1 ) {
                    event.preventDefault();
                    if ( jTargetSection.is( '.e-scroll-reveal .paragraph-section' ) === true ) {
                        jTargetSection.addClass( e.settings.classes.resetTransform );
                    }
                    intScrollTarget = jTarget.offset().top;
                    e.scrollToAnchor.animate( intScrollTarget );
                }
            }
        } );
    },
    animate: function ( intScrollTarget ) {
        var jWindow = jQuery( window );
        intScrollTarget = parseInt( intScrollTarget, 10 );
        if ( intScrollTarget !== 0 ) {
            if ( jQuery( '.js-e-page-auto-hide-menu' ).length === 1 ) {
                e.settings.scrollToAnchor.stickyElements = e.settings.scrollToAnchor.stickyElements.replace( '.js-e-header', '' );
            }
            jQuery( e.settings.scrollToAnchor.stickyElements ).each( function () {
                intScrollTarget -= jQuery( this ).height();
            } );
            intScrollTarget -= jWindow.height() / 100 * e.settings.scrollToAnchor.nudge;
            intScrollTarget = Math.max( intScrollTarget, 0 );
        }
        if ( e.scrollToAnchor.animating === false ) {
            e.scrollToAnchor.animating = true;
            jQuery( 'html,body' ).animate( {
                scrollTop: intScrollTarget
            }, Math.floor( Math.abs( jWindow.scrollTop() - intScrollTarget ) * e.settings.scrollToAnchor.speed ), e.settings.scrollToAnchor.easing, function () {
                e.scrollToAnchor.animating = false;
            } );
        }
    },
    backToTop: {
        init: function () {
            if ( jQuery( '.js-e-page-back-to-top' ).length === 1 ) {
                e.scrollToAnchor.backToTop.requestingAnimation = false;
                e.scrollToAnchor.backToTop.bindEvents();
            }
        },
        bindEvents: function () {
            jQuery( window ).on( 'scroll resize', function () {
                if ( e.scrollToAnchor.backToTop.requestingAnimation === false ) {
                    window.requestAnimationFrame( function () {
                        e.scrollToAnchor.backToTop.toggleVisibility();
                        e.scrollToAnchor.backToTop.requestingAnimation = false;
                    } );
                }
                e.scrollToAnchor.backToTop.requestingAnimation = true;
            } );
        },
        toggleVisibility: function () {
            var jWindow = jQuery( window ),
                windowScrollTop = jWindow.scrollTop(),
                jbackToTopLink = jQuery( '.js-e-back-to-top' );
            e.scrollToAnchor.scrollDirectionCurrent = windowScrollTop > e.scrollToAnchor.lastScrollTop ? 'down' : 'up';
            if ( e.scrollToAnchor.scrollDirectionCurrent !== e.scrollToAnchor.scrollDirectionNew ) {
                if ( windowScrollTop > jWindow.height() * e.settings.scrollToAnchor.backToTop.revealOffset ) {
                    jbackToTopLink.addClass( 'is-visible' );
                }
                else {
                    jbackToTopLink.removeClass( 'is-visible' );
                }
            }
            e.scrollToAnchor.scrollDirectionLast = e.scrollToAnchor.scrollDirectionCurrent;
        }
    }
};

