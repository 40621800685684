( function () {
    'use strict';
    jQuery( window ).on( 'load', function () {
        if ( e.pageLoading !== undefined ) {
            e.pageLoading.showPageCanvas();
            e.pageLoading.hideLoadingOverlay();
        }
        e.scrollReveal.init();
        e.parallax.init();
        e.scrollToAnchor.init();
        e.bootstrap.dropdown.init();
        e.bootstrap.carousel.init();
        e.bootstrap.modal.init();
        e.paragraph.init();
    } );
}() );

