e.navigation = {
    init: function () {
        e.navigation.bindEvents();
        e.navigation.sticky.init();
    },
    bindEvents: function () {
        var jNavContainer = jQuery( '.js-e-nav-container' ).on( 'swipeleft', function () {
            jNavContainer.modal( 'hide' );
        } ).on( 'shown.bs.modal', function () {
            setTimeout( e.navigation.revealActive, e.settings.navigation.revealActive.delay );
        } ).on( 'click', 'a', function ( event ) {
            //TODO: Lægges ud i funktion.
            var jThis = jQuery( this );
            if ( jThis.is( '.in [data-toggle="dropdown"]' ) === true ) {
                event.stopPropagation();
            }
            if ( jThis.is( '.in [data-collapse-toggle="true"]' ) === true ) {
                event.preventDefault();
                jQuery( this.getAttribute( 'data-collapse-target' ) ).collapse( 'toggle' );
            }
        } );
    },
    revealActive: function () {
        var jNavContainer = jQuery( '.js-e-nav-container' ),
            jNavItemActive = jNavContainer.find( '.nav-item.is-active' ).last(),
            jNavItemActiveParent = jNavItemActive.closest( '.nav' ).closest( '.nav-item' ),
            jOffsetElement = jNavItemActiveParent,
            intScrollTarget = 0;
        if ( jNavItemActive.length === 1 ) {
            // Hvis parent item ikke findes, eller er for høj til viewport, sættes offset element til det aktive item.
            if ( jNavItemActiveParent.length === 0 || jNavItemActiveParent.height() > jNavContainer.height() ) {
                jOffsetElement = jNavItemActive;
            }
            // Beregn relativt offset til containeren.
            intScrollTarget = jOffsetElement.offset().top - jNavContainer.offset().top;
            jNavContainer.animate( {
                scrollTop: intScrollTarget
            }, Math.floor( intScrollTarget * e.settings.navigation.revealActive.speed ), e.settings.navigation.revealActive.easing );
        }
    },
    sticky: {
        init: function () {
            if ( jQuery( '.js-e-page-auto-hide-menu' ).length === 1 ) {
                e.navigation.sticky.lastScrollTop = jQuery( window ).scrollTop();
                e.navigation.sticky.requestingAnimation = false;
                e.navigation.sticky.bindEvents();
            }
        },
        bindEvents: function () {
            jQuery( window ).on( 'scroll', function () {
                if ( e.navigation.sticky.requestingAnimation === false ) {
                    window.requestAnimationFrame( function () {
                        e.navigation.sticky.toggleVisibility();
                        e.navigation.sticky.requestingAnimation = false;
                    } );
                }
                e.navigation.sticky.requestingAnimation = true;
            } );
        },
        toggleVisibility: function () {
            var windowScrollTop = jQuery( window ).scrollTop(),
                jNavContainer = jQuery( '.js-e-header' );
            e.navigation.sticky.scrollDirectionCurrent = windowScrollTop > e.navigation.sticky.lastScrollTop ? 'down' : 'up';
            if ( windowScrollTop <= 0 ) { // Check for iOS scroll bounce
                jNavContainer.removeClass( 'is-hidden' );
            }
            else if ( Math.abs( windowScrollTop - e.navigation.sticky.lastScrollTop ) > e.settings.navigation.autoHideTolerance[ e.navigation.sticky.scrollDirectionCurrent ] ) {
                if ( e.navigation.sticky.scrollDirectionCurrent === 'down' && e.navigation.sticky.scrollDirectionLast !== 'down' ) {
                    jNavContainer.addClass( 'is-hidden' );
                }
                else if ( e.navigation.sticky.scrollDirectionCurrent === 'up' && e.navigation.sticky.scrollDirectionLast !== 'up' ) {
                    jNavContainer.removeClass( 'is-hidden' );
                }
                e.navigation.sticky.lastScrollTop = windowScrollTop;
                e.navigation.sticky.scrollDirectionLast = e.navigation.sticky.scrollDirectionCurrent;
            }
        }
    }
};

