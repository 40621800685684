e.forms = {
    init: function () {
        e.forms.validate.init();
        e.forms.setActiveState();
    },
    setActiveState: function () {
        //Toggle class on form groups to focus active fields and labels.
        jQuery( '.js-e-form-dw-editor' ).find( '.form-group :input' ).on( 'focus blur', function ( event ) {
            var jThisFormGroup = jQuery( this ).closest( '.form-group' );
            if ( event.type === 'focus' ) {
                jThisFormGroup.addClass( 'has-focus' );
            }
            else {
                jThisFormGroup.removeClass( 'has-focus' );
            }
        } );
    },
    validate: {
        init: function () {
            if ( jQuery( '.js-e-form-dw-editor, form[data-validate="true"]' ).length > 0 ) {
                e.forms.validate.setDefaults();
                e.forms.validate.bind();
                e.forms.validate.loadLocalLang();
            }
        },
        setDefaults: function () {
            //Set validation plugin default settings.
            jQuery.validator.setDefaults( {
                //debug: true,
                validClass: '',
                errorClass: 'has-danger',
                highlight: function ( element, errorClass, validClass ) {
                    jQuery( element ).addClass( 'form-control-danger' ).removeClass( 'form-control-success' ).closest( '.form-group' ).addClass( errorClass ).removeClass( validClass );
                },
                unhighlight: function ( element, errorClass, validClass ) {
                    jQuery( element ).removeClass( 'form-control-danger' ).addClass( 'form-control-success' ).closest( '.form-group' ).removeClass( errorClass ).addClass( validClass );
                },
                errorPlacement: function ( jError, jElement ) {
                    jElement.closest( '.form-group' ).append( jError );
                },
                focusInvalid: false,
                invalidHandler: function ( form, validator ) {
                    var jFirstError = jQuery( validator.invalidElements()[ 0 ] );
                    if ( e.util.isInViewport( jFirstError ) === true ) {
                        validator.focusInvalid();
                    }
                    else {
                        jFirstError.focus();
                        jQuery( window ).scrollTop( jFirstError.closest( '.form-group' ).offset().top * 0.875 );
                    }
                },
                submitHandler: function ( form ) {
                    //Read Dynamicweb spam script from inline script block inside the forms.
                    DwSpamScripts = DwSpamScripts || {};
                    DwSpamScripts[ form.id ]( form );
                    //console.log( 'Submit' );
                    form.submit();
                }
            } );
        },
        bind: function () {
            //Bind validation method to all Dynamiweb forms and custom forms with data-validate="true" using default settings.
            jQuery( '.js-e-form-dw-editor, form[data-validate="true"]' ).each( function () {
                jQuery( this ).validate();
            } );
        },
        loadLocalLang: function () {
            if ( e.settings.lang === 'da' ) {
                jQuery.extend( jQuery.validator.messages, e.settings.forms.validate.messages.da );
            }
            else if ( e.settings.lang !== 'en' ) {
                if ( e.settings.lang === 'nb' || e.settings.lang === 'nn' ) {
                    e.settings.lang = 'no';
                }
                if ( e.settings.forms.validate.messages[ e.settings.lang ] !== undefined ) {
                    jQuery.extend( jQuery.validator.messages, e.settings.forms.validate.messages[ e.settings.lang ] );
                }
                else {
                    jQuery.ajax( {
                        url: '//ajax.aspnetcdn.com/ajax/jquery.validate/1.11.0/localization/messages_' + e.settings.lang + '.js',
                        dataType: 'script',
                        cache: true
                    } );
                }
            }
        }
    }
};

/*
jQuery.validator.addMethod( 'localizedNumber', function ( value, element ) {
    var regExpLocalizedNumber = new RegExp( '^-?(?:\\d+|\\d{1,3}(?:\\' + vetoryl.logbook.number.intergerSeperator + '\\d{3})+)?(?:\\' + vetoryl.logbook.number.decimalSeperator + '\\d+)?$' );
    return this.optional( element ) || regExpLocalizedNumber.test( value );
}, jQuery.validator.messages.number );
*/

