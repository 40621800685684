e.bootstrap.dropdown = {
    init: function () {
        e.bootstrap.dropdown.bindEvents();
    },
    bindEvents: function () {
        jQuery( '.dropdown-menu' ).on( 'click.bs.dropdown.data-api', function ( event ) {
            if ( jQuery( this ).parent().hasClass( 'open' ) === true && event.target.getAttribute( 'data-toggle' ) !== 'dropdown' && event.target.parentNode.getAttribute( 'data-toggle' ) !== 'dropdown' ) {
                event.stopPropagation();
            }
        } );
    }
};

