e.bootstrap.modal = {
    init: function () {
        e.bootstrap.modal.bindEvents();
    },
    bindEvents: function () {
        //TODO: Laves om så der i stedet tages højde for om modal'en ligger i et element med Translate og will-change.
        var strClasslistTransform = [ '.' + e.settings.scrollReveal.classAnimateInOnLoad, '.' + e.settings.scrollReveal.classAnimateInOnScroll ].join( ',' );
        jQuery( '.modal' ).on( 'show.bs.modal', function () {
            jQuery( strClasslistTransform ).addClass( e.settings.classes.resetTransform );
        } );
        jQuery( '.modal' ).on( 'hidden.bs.modal', function () {
            jQuery( strClasslistTransform ).removeClass( e.settings.classes.resetTransform );
        } );
    }
};

